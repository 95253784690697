<template>
  <div class="container">
    <div>
      <div class="color-selector">
        <div v-for="(css,color) in colors" :key="color">
          <input type="radio" v-model="type" name="type" :value="color" :id="`color-`+color">
          <label :for="`color-`+color" :style="css">{{ color }}</label>
        </div>
      </div>
    </div>
    <div>
      <input
          type="text"
          placeholder="Title"
          v-model="title">
    </div>
    <div>
      <textarea v-model="textbox" placeholder="Text"></textarea>
      <p>Surround text with # for bold</p>
    </div>
    <div>
      <div v-html="output"></div>
    </div>
    <div>
      <textarea
          v-model="output"
          readonly></textarea>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Infobox',
  data: function () {
    return {
      colors: {
        blue: 'background: #0d6efd;color: #fff',
        indigo: 'background: #6610f2; color: #fff',
        purple: 'background: #6f42c1; color: #fff',
        pink: 'background: #d63384; color: #fff',
        red: 'background: #dc3545; color: #fff',
        orange: 'background: #fd7e14; color: #000',
        yellow: 'background: #ffc107; color: #000',
        green: 'background: #198754; color: #fff',
        teal: 'background: #20c997; color: #000',
        cyan: 'background: #0dcaf0; color: #000',
        gray: 'background: #adb5bd; color: #000',
        black: 'background: #000; color: #fff',
        white: 'background: #fff; color: #000',
      },
      textbox: '',
      type: 'blue',
      title: '',
      alwaysCss: [
        'max-width: 400px',
        'margin: 30px auto',
        'padding: 20px',
        'box-shadow: 2px 2px 5px 0 rgba(50, 50, 50, 0.75)',
        'text-align: center',
        'border-radius: 6px'
      ]
    };
  },
  computed: {
    output() {
      let lines = [];
      let css = this.alwaysCss.slice(0);
      css.push(this.colors[this.type]);
      lines.push('<div style="'+css.join(';')+'">');
      if (this.title !== '') {
        lines.push('<p style="font-size: 20px;padding: 0 0 20px;margin: 0">'+this.title+'</p>');
      }

      lines.push('<p style="padding: 0; margin: 0;">'+this.textbox.replaceAll(/\n/gi, '<br>').replaceAll(/#(.+?)#/gi, '<strong>$1</strong>')+'</p>');
      lines.push('</div>');
      return lines.join('');
    }
  },
  watch: {
    textbox() {
      localStorage.setItem('text', this.textbox);
    },
    type() {
      localStorage.setItem('type', this.type);
    },
    title() {
      localStorage.setItem('title', this.title);
    }
  },
  mounted() {
    let storedTitle = localStorage.getItem('title');
    if (storedTitle !== null) {
      this.title = storedTitle;
    }
    let storedType = localStorage.getItem('type');
    if (storedType !== null) {
      this.type = storedType;
    }
    let storedText = localStorage.getItem('text');
    if (storedText !== null) {
      this.textbox = storedText;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container {
  max-width: 600px;
  margin: 0 auto;
  display: grid;
  gap: 15px;
  p {
    margin: 0;
    padding: 0;
    font-style: italic;
  }

  .color-selector {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    input {
      display: none;
      &:checked+label {
        //text-decoration: underline;
      }
    }
    label {
      transition: 0.3s;
      display: block;
      padding: 10px 15px;
      text-transform: capitalize;
    }
  }

  input[type="text"],
  textarea {
    width: 100%;
    padding: 10px;
  }
  textarea {
    height: 200px;
  }
}
</style>
