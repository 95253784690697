<template>
  <div id="app">
    <Infobox />
  </div>
</template>

<script>

import Infobox from "@/components/Infobox";

export default {
  name: 'App',
  components: {
    Infobox
  }
}
</script>

<style>
html {
  height: 100vh;
  background: linear-gradient(33deg, #3c3c3c, darkcyan);
  color: #fff;
  font-family: 'Noto Sans', sans-serif;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
}
</style>
